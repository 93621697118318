<template>
  <div>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            width="400"
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-radio-group v-model="type" hide-details label="Type de code promos">
            <v-radio
                    v-for="(type,i) in types"
                    :key="i"
                    :label="type.name"
                    :value="type.value"
            ></v-radio>
          </v-radio-group>

          <v-radio-group v-model="is_active" hide-details label="Statut">
            <v-radio
                    v-for="(item,i) in statuses"
                    :key="i"
                    :label="item.name"
                    :value="item.value"
            ></v-radio>
          </v-radio-group>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn block
                 color="primary"
                 depressed type="submit"
                 @click="handleFilter">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            type: null,
            assignment_type: null,
            is_active: 'all',
        }
    },
    methods: {
        open() {
            this.type = null
            this.is_active = 'all'
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        handleFilter() {
            let data = {
                type: this.type,
                is_active: this.is_active,
            }
            this.$emit('filter', data)
            this.close()
        },
    },
    computed: {
        types() {
            return [
                {name: 'Pourcentage(%)', value: 'percentage'},
                {name: 'Montant(DZD)', value: 'amount'},
            ]
        },
        statuses() {
            return [
                {name: 'Tous', value: 'all'},
                {name: 'Active', value: true},
                {name: 'Inactive', value: false},
            ]
        },
    },
}
</script>

