<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>

              <v-btn class="rounded-lg"
                     color="gifty"
                     dark
                     depressed
                     to="/promo-codes/create"
              >
                <v-icon left>mdi-plus</v-icon>
                Ajouter un code promo
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Rechercher..."
                          rounded
                          single-line
                  />
                </div>
                <v-spacer/>

                <v-btn class="rounded-lg"
                       color="grey"
                       text
                       @click="$refs.filterDialog.open()"
                >
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="grey"
                       icon
                       @click="getPromoCodes(null)">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="promoCodes.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Statut</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Valeur</th>
                        <th>Services</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Jours restants</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(promoCode ,index) in promoCodes"
                          :key="index"
                      >
                        <td>
                          <v-switch v-model="promoCode.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    dense
                                    hide-details
                                    inset
                                    readonly
                                    @click="changeStatus(promoCode.id,index)"></v-switch>
                        </td>

                        <td class="font-weight-medium text-primary">
                          {{ promoCode.code }}
                        </td>

                        <td>
                          {{ promoCode.type === 'percentage' ? 'Pourcentage(%)' : 'Montant(DZD)' }}
                        </td>

                        <td class="font-weight-medium gifty--text text-no-wrap">
                          {{ promoCode.value }}
                          {{ promoCode.type === 'percentage' ? '%' : 'DZD' }}
                        </td>

                        <td>
                          <v-chip color="info"
                                  dark
                                  label
                                  small
                                  @click="$refs.servicesDialog.open(promoCode.services)">
                            <v-icon left small>mdi-layers-triple</v-icon>
                            {{ promoCode.services.length }}
                          </v-chip>
                        </td>
                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-in
                          </v-icon>
                          {{ promoCode.start_date_formated }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-out
                          </v-icon>
                          {{ promoCode.end_date_formated }}
                        </td>

                        <td>
                          <v-chip :color="promoCode.status.color" dark small> {{ promoCode.status.value }}</v-chip>
                        </td>

                        <td>
                          <div class="d-flex">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="gifty"
                                       icon
                                       v-bind="attrs"
                                       @click="$refs.detailsDialog.open(promoCode)"
                                       v-on="on">
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Détails</span>
                            </v-tooltip>


                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                       v-bind="attrs"
                                       v-on="on"
                                >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list dense>

                                <v-list-item @click="$refs.prolongDialog.open(promoCode)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-clock-start</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Prolonger</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="$refs.updateCodeDialog.open(promoCode)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Modifer le code</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="$refs.updateValueDialog.open(promoCode)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Modifer la valeur</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="$refs.updateAmountDialog.open(promoCode)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Modifier les montants</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="$refs.updateServicesDialog.open(promoCode)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Modifier les services</v-list-item-title>
                                </v-list-item>


                                <v-list-item :to="'/promo-codes/'+promoCode.id+'/update'">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Modifier l'affectation</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="remove(promoCode.id)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Supprimer</v-list-item-title>
                                </v-list-item>

                              </v-list>
                            </v-menu>

                          </div>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getPromoCodes"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getPromoCodes"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <FilterDialog ref="filterDialog" @filter="getPromoCodes"/>
      <DetailsDialog ref="detailsDialog"/>
      <ProlongDialog ref="prolongDialog" @refresh="getPromoCodes"/>
      <UpdateValueDialog ref="updateValueDialog" @refresh="getPromoCodes"/>
      <UpdateAmountDialog ref="updateAmountDialog" @refresh="getPromoCodes"/>
      <UpdateServicesDialog ref="updateServicesDialog" @refresh="getPromoCodes"/>
      <UpdateCodeDialog ref="updateCodeDialog" @refresh="getPromoCodes"/>
      <ServicesDialog ref="servicesDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "./components/FilterDialog.vue";
import DetailsDialog from "./components/DetailsDialog.vue";
import ProlongDialog from "./components/ProlongDialog.vue";
import UpdateValueDialog from "./components/UpdateValueDialog.vue";
import UpdateAmountDialog from "./components/UpdateAmountDialog.vue";
import UpdateServicesDialog from "./components/UpdateServicesDialog.vue";
import UpdateCodeDialog from "./components/UpdateCodeDialog.vue";
import ServicesDialog from "./components/ServicesDialog.vue";

export default {
   components: {
      ServicesDialog,
      NoResults,
      FilterDialog,
      DetailsDialog,
      ProlongDialog,
      UpdateAmountDialog,
      UpdateServicesDialog,
      UpdateCodeDialog,
      UpdateValueDialog
   },
   data() {
      return {
         keyword: '',
         id: '',
         overlay: false,
         isLoading: false,
         promoCodes: [],

         total: 0,
         perPage: 10,
         pagination: {
            current: 1,
            total: 0
         },
      };
   },
   methods: {
      getPromoCodes(filter) {
         this.isLoading = true
         HTTP.get('/v1/promo-codes?page=' + this.pagination.current, {
            params: {
               perPage: this.perPage,
               type: filter ? filter.type : null,
               is_active: filter ? filter.is_active : null,
               keyword: this.keyword
            }
         }).then(res => {
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            this.promoCodes = res.data.data.data

            this.isLoading = false
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      remove(id) {
         this.id = id;
         this.$confirm_dialog = true;
      },
      deleteItem() {
         this.overlay = true;
         HTTP.post("/v1/promo-codes/delete", {
            id: this.id,
         }).then(() => {
            this.overlay = false;
            let i = this.promoCodes.findIndex((el) => el.id === this.id);
            this.$delete(this.promoCodes, i);
            this.id = null;
            this.$successMessage = "Cet élément a été supprimé avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
      changeStatus(id, index) {
         this.overlay = true;
         HTTP.post("/v1/promo-codes/change-status", {
            id: id,
         }).then(() => {
            this.overlay = false;
            this.promoCodes[index].is_active = !this.promoCodes[index].is_active
            this.$successMessage = "Cet élément a été modifié avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
   },
   created() {
      this.getPromoCodes();
   },
   watch: {
      $is_confirm: function (el) {
         if (el) {
            this.deleteItem();
            this.$is_confirm = false;
         }
      },
      perPage() {
         this.pagination.current = 1
         this.getPromoCodes();
      },
      keyword() {
         this.getPromoCodes();
      },
   }
};
</script>

<style scoped></style>
