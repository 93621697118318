<template>
  <div>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            width="450"
    >
      <v-card>

        <v-card-title>
          Services
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-0">


          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Service</th>
                <th>Valeur par défaut si il existe une promotion</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in services" :key="index">
                <td class="font-weight-medium">
                  {{ item.name }}
                </td>
                <td class="font-weight-medium gifty--text">
                  {{ getDefaultValue(item.pivot.default_value) }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            services: [],
        }
    },
    methods: {
        open(services) {
            this.dialog = true
            this.services = services
        },
        close() {
            this.dialog = false
        },
        getDefaultValue(val) {
            switch (val) {
                case 'max':
                    return "Maximum";
                case 'min':
                    return "Minimum";
                case 'sum':
                    return "Somme(Code promo + Promotion)";
                case 'promo_code':
                    return "Code promo";
                case 'promotion':
                    return "Promotion";

            }
        },
    }
}
</script>

