<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="400"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Modifier le code
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">
          <v-text-field v-model="form.name"
                        :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                        :error-messages="errors.name"
                        label="Nom *"
          ></v-text-field>
          <v-text-field v-model="form.code"
                        :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                        :error-messages="errors.code"
                        label="Code *"
          ></v-text-field>
        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                 :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            btnLoading: false,
            form: {
                id: null,
                code: null,
                name: null,
            },
            errors: {},
            promoCode: {
                status : {}
            }
        }
    },
    methods: {
        open(promoCode) {
            this.dialog = true
            this.errors = {}
            this.promoCode = promoCode
            this.form = {
                id: promoCode.id,
                code: promoCode.code,
                name: promoCode.name
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.errors = {}
            let url = '/v1/promo-codes/update-code'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Ce code promo a été modifié avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },

    }
}
</script>

