<template>
  <div>
    <v-dialog
            v-model="dialog"
            scrollable
            width="800">

      <v-card>

        <v-card-title>
          Détails
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">


          <v-card outlined>

            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="article,article"/>
            </v-card-text>

            <v-card-text v-else>
              <v-card-title class="font-weight-medium">
                Nombre de transactions par jour
                <v-spacer/>
                <v-btn class="v-btn--active"
                       color="gifty"
                       icon
                       @click="getChartData">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-subtitle class="text-primary">
                Total : {{ promoCode.count_of_uses }}
              </v-card-subtitle>

              <LineChart :data="chart.data" :labels="chart.labels"/>

            </v-card-text>

          </v-card>

          <v-card class="mt-5" outlined>
            <v-simple-table>
              <template v-slot:default>
                <tbody>

                <tr>
                  <td class="font-weight-medium">Jours restants</td>
                  <td>
                    <v-chip :color="promoCode.status.color" dark small> {{ promoCode.status.value }}</v-chip>
                  </td>
                </tr>

                <tr>
                  <td class="font-weight-medium">Code</td>
                  <td class="font-weight-medium text-primary">{{ promoCode.code }}</td>
                </tr>

                <tr class="grey lighten-5">
                  <td class="font-weight-medium">Nom</td>
                  <td>{{ promoCode.name }}</td>
                </tr>

                <tr>
                  <td class="font-weight-medium">Type</td>
                  <td>{{ promoCode.type === 'percentage' ? 'Pourcentage(%)' : 'Montant(DZ)' }}</td>
                </tr>

                <tr>
                  <td class="font-weight-medium">Tout le monde</td>
                  <td>
                    <v-icon v-if="promoCode.assignment_type === 'all'" color="success">
                      mdi-circle-outline
                    </v-icon>
                    <v-icon v-else color="success">mdi-check-circle</v-icon>
                  </td>
                </tr>

                <tr class="grey lighten-5">
                  <td class="font-weight-medium">Valeur</td>
                  <td class="text-no-wrap font-weight-medium gifty--text">
                    {{ promoCode.value }}
                    {{ promoCode.type === 'percentage' ? '%' : 'DZD' }}
                  </td>
                </tr>

                <tr>
                  <td class="font-weight-medium text-no-wrap">Montant minimum de la transaction</td>
                  <td>{{ promoCode.minimum_amount }} DZD</td>
                </tr>

                <tr class="grey lighten-5">
                  <td class="font-weight-medium text-no-wrap">Montant maximum de la réduction</td>
                  <td>{{ promoCode.maximum_amount }} DZD</td>
                </tr>

                <tr>
                  <td class="font-weight-medium text-no-wrap">Nombre maximum d'utilisation</td>
                  <td>{{ promoCode.maximum_usage }}</td>
                </tr>

                <tr class="grey lighten-5">
                  <td class="font-weight-medium text-no-wrap">Nombre maximum d'utilisation par utilisateur</td>
                  <td>{{ promoCode.maximum_usage_per_user }}</td>
                </tr>

                <tr>
                  <td class="font-weight-medium">Planification</td>
                  <td class="text-no-wrap">
                    <v-icon :style="{marginTop:'-3px'}"
                            color="gifty"
                    >mdi-clock-in
                    </v-icon>
                    {{ promoCode.start_date_formated }}
                    ****
                    <v-icon :style="{marginTop:'-3px'}"
                            color="gifty"
                    >mdi-clock-out
                    </v-icon>
                    {{ promoCode.end_date_formated }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-medium">Date de création</td>
                  <td class="text-no-wrap">
                    <v-icon :style="{marginTop:'-3px'}"
                            color="gifty"
                    >mdi-clock
                    </v-icon>
                    {{ promoCode.created_at }}
                  </td>
                </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-card>


        </v-card-text>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import LineChart from "@/components/charts/LineChart.vue";
import {HTTP} from "@/http-common";

export default {
    components: {LineChart},
    data() {
        return {
            isLoading: false,
            dialog: false,
            promoCode: {
                status: {}
            },
            chart: {
                data: [],
                labels: []
            }
        }
    },
    methods: {
        open(promoCode) {
            this.dialog = true
            this.promoCode = promoCode
            this.getChartData()
        },
        close() {
            this.dialog = false
        },
        getChartData() {
            this.isLoading = true
            HTTP.get('/v1/promo-codes/' + this.promoCode.id + '/chart-data').then(res => {
                this.isLoading = false
                this.chart = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        }
    }
}
</script>

