<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="600"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Modifier les montants
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-row>
            <v-col>
              <v-text-field v-model.trim="form.minimum_amount"
                            :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                            :error-messages="errors.minimum_amount"
                            hide-details="auto"
                            label="Montant minimum de la transaction *"
                            suffix="DZD"/>
            </v-col>
            <v-col>
              <v-text-field v-model.trim="form.maximum_amount"
                            :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                            :error-messages="errors.maximum_amount"
                            hide-details="auto"
                            label="Montant maximum de la réduction *"
                            suffix="DZD"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.trim="form.maximum_usage"
                            :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                            :error-messages="errors.maximum_usage"
                            label="Nombre maximum d'utilisation *"/>
            </v-col>
            <v-col>
              <v-text-field v-model.trim="form.maximum_usage_per_user"
                            :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                            :error-messages="errors.maximum_usage_per_user"
                            label="Nombre maximum d'utilisation par utilisateur *"/>
            </v-col>
          </v-row>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="promoCode.status.state === 'completed' || promoCode.status.state === 'in_progress'"
                 :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            btnLoading: false,
            form: {
                id: null,
                minimum_amount: null,
                maximum_amount: null,
                maximum_usage: null,
                maximum_usage_per_user: null,
            },
            errors: {},
            promoCode: {
                status : {}
            }
        }
    },
    methods: {
        open(promoCode) {
            this.dialog = true
            this.errors = {}
            this.promoCode = promoCode
            this.form = {
                id: promoCode.id,
                minimum_amount: promoCode.minimum_amount,
                maximum_amount: promoCode.maximum_amount,
                maximum_usage: promoCode.maximum_usage,
                maximum_usage_per_user: promoCode.maximum_usage_per_user
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.errors = {}
            let url = '/v1/promo-codes/update-amount'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Ce code promo a été modifié avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },

    }
}
</script>

