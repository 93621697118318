<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Prolonger
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-row>

            <v-col cols="8">
              <v-menu
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_date"
                                :error-messages="errors.start_date"
                                :min="dateNow"
                                append-icon="mdi-calendar"
                                hide-details="auto"
                                label="Date début *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.start_date"
                               :min="dateNow"
                               @input="[menuStartDate = false,form.end_date = null]"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-menu
                      v-model="menuStartTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_time"
                                :error-messages="errors.start_time"
                                append-icon="mdi-clock"
                                hide-details="auto"
                                label="Heure de début *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="form.start_time"
                               @input="menuStartTime = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="8">
              <v-menu v-model="menuEndDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.end_date"
                                :disabled="!form.start_date || !form.start_time"
                                :error-messages="errors.end_date"
                                append-icon="mdi-calendar"
                                label="Date de fin *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.end_date"
                               :min="form.start_date"
                               @input="menuEndDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-menu
                      v-model="menuEndTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.end_time"
                                :disabled="!form.start_date || !form.start_time"
                                :error-messages="errors.end_time"
                                append-icon="mdi-clock"
                                label="Heure de fin *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="form.end_time"
                               @input="menuEndTime = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

          </v-row>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import moment from "moment";

export default {
    data() {
        return {
            dialog: false,
            dateNow: moment().format('YYYY-MM-DD'),
            menuStartDate: false,
            menuStartTime: false,
            menuEndDate: false,
            menuEndTime: false,
            btnLoading: false,
            form: {
                id: null,
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
            },
            errors: {},

            promoCode: {
                status: {}
            }
        }
    },
    methods: {
        open(promoCode) {
            this.dialog = true
            this.errors = {}
            this.promoCode = promoCode
            this.form = {
                id: promoCode.id,
                start_date: moment(promoCode.start_date).format('YYYY-MM-DD'),
                start_time: moment(promoCode.start_date).format('HH:mm'),
                end_date: moment(promoCode.end_date).format('YYYY-MM-DD'),
                end_time: moment(promoCode.end_date).format('HH:mm'),
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.errors = {}
            let url = '/v1/promo-codes/prolong'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Ce code promo a été prolongé avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },

    }
}
</script>

