<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="600"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Modifier les services
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-6">

          <v-autocomplete v-model="form.services"
                          :error-messages="errors.services"
                          :items="services"
                          :loading="serviceLoading"
                          chips
                          class="mb-2"
                          clearable
                          deletable-chips
                          hide-details="auto"
                          item-text="name"
                          label="Services *"
                          multiple
                          return-object
                          small-chips>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img contain :src="getImageLink(data.item.image)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                                  (+{{ form.services.length - 1 }} autres)
                               </span>
            </template>

          </v-autocomplete>

          <v-btn :disabled="services.length===0"
                 class="secondary"
                 depressed
                 small
                 @click="selectAllServices">
            <v-icon left>
              {{ selectAllServicesIcon }}
            </v-icon>
            Sélectionner tout
          </v-btn>

          <v-card v-if="form.services.length" outlined class="rounded-lg mt-3">
            <v-simple-table>

              <template v-slot:default>
                <thead>
                <tr>
                  <th>Service</th>
                  <th width="60%">Valeur par défaut si il existe une promotion</th>
                  <th width="2%">#</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(service,index) in form.services" :key="index">
                  <td class="font-weight-medium">
                    <div class="pa-2">
                      <v-avatar size="40" tile>
                        <v-img contain :src="getImageLink(service.image)"></v-img>
                      </v-avatar>
                      {{ service.name }}
                    </div>
                  </td>
                  <td>
                    <v-select v-model="service.default_value"
                              :items="defaultValues"
                              dense
                              hide-details
                              item-text="name"
                              item-value="value"
                              outlined></v-select>
                  </td>
                  <td>
                    <v-icon color="red" @click="$delete(form.services,index)">mdi-close-circle</v-icon>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            btnLoading: false,
            form: {
                id: null,
                services: [],
            },
            errors: {},
            promoCode: {
                status: {}
            },

            serviceLoading: false,
            services: [],

            defaultValues: [
                {name: "Maximum", value: "max"},
                {name: "Minimum", value: "min"},
                {name: "Somme(Code promo + Promotion)", value: "sum"},
                {name: "Code promo", value: "promo_code"},
                {name: "Promotion", value: "promotion"},
            ]
        }
    },
    methods: {
        open(promoCode) {
            this.dialog = true
            this.errors = {}
            this.promoCode = promoCode
            this.form = {
                id: promoCode.id,
                services: promoCode.services.map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                        image: el.image,
                        default_value: el.pivot.default_value,
                    }
                })
            }
            if (!this.services.length) {
                this.getServices()
            }
        },
        close() {
            this.dialog = false
        },
        getServices() {
            this.serviceLoading = true
            HTTP.get('/v1/fees/services').then(res => {
                this.serviceLoading = false
                let services = res.data.data
                if (services.length) {
                    this.services = services.map(el => {
                        return {
                            id: el.id,
                            name: el.name,
                            image: el.image,
                            default_value: 'max',
                        }
                    })
                }
            }).catch(err => {
                this.serviceLoading = false
                console.log(err)
            })
        },
        save() {
            this.errors = {}
            let url = '/v1/promo-codes/update-services'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Ce code promo a été modifié avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },
        selectAllServices() {
            if (this.form.services.length === this.services.length) {
                this.form.services = []
            } else {
                this.form.services = this.services.map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                        image: el.image,
                        default_value: 'max',
                    }
                })
            }
        },
    },
    computed: {
        selectAllServicesIcon() {
            if (this.form.services.length === this.services.length) return 'mdi-close-box'
            if (this.form.services.length > 0 && this.form.services.length !== this.services.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    }
}
</script>

